export const nameValidate = {
  required: {
    value: true,
    message: "Please enter name",
  },
  pattern: {
    value: /^[a-z ,.'-]+$/i,
    message: "Invalid Name",
  }
};

export const phoneValidate = {
  required: {
    value: true,
    message: "Please enter phone number",
  },
  minLength: {
    value: 10,
    message: "Phone number must be 10 digits long not less",
  },
  maxLength: {
    value: 10,
    message: "Phone number must be 10 digits long not more",
  },
  pattern: {
    value: /^[0-9]*$/,
    message: "Phone number must be only digits",
  },
};

export const usernameValidate = {
  required: {
    value: true,
    message: "Please enter username",
  },
  minLength: {
    value: 6,
    message: "Username must be at least 6 characters long",
  },
  pattern: {
    value: /^[a-zA-Z0-9]+$/,
    message: "Username must be alphanumeric",
  },
};

export const emailValidate = {
  required: {
    value: true,
    message: "Please enter an email address",
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Email address is not valid",
  },
};

export const passwordValidate = {
  required: {
    value: true,
    message: "Please enter password",
  },
  minLength: {
    value: 6,
    message: "Password must be at least 6 characters long",
  },
};

export const genderValidate = {
  required: {
    value: true,
    message: "Please choose a gender",
  }
};
