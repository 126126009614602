import { Box, Button, ChakraProvider, FormControl, FormErrorMessage, FormLabel, Heading, Input, Radio, RadioGroup, Select, HStack, Stack, useToast, Center } from "@chakra-ui/react"
import { emailValidate, genderValidate, nameValidate, passwordValidate, phoneValidate, usernameValidate } from "./form-validate"
import { useState } from "react"
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, db } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

function useRegister() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function register({
    name,
    phone,
    username,
    email,
    password,
    gender,

    organization,
    groupName,
    groupWebsite,
    street,
    state,
    zipCode
  }) {
    setLoading(true);

      try {
        if(gender != 1 && gender != 2) {
          throw new Error("Enter a valid gender");
        }

        const res = await createUserWithEmailAndPassword(auth, email, password);
        sendEmailVerification(res.user);
        

        await setDoc(doc(db, `users`, res.user.uid), {
          id: res.user.uid,
          email: email,
          phone: phone,
          name: name.toLowerCase(),
          username: username.toLowerCase(),
          avatar: "",
          gender: (gender == 1) ? "Male" : "Female",
          organizations: [groupName],
          date: Date.now(),
        });

        await setDoc(doc(db, `Organizations`, groupName), {
          id: groupName,
          admins: [res.user.uid],
          type: organization,
          website: groupWebsite,
          street: street,
          state: state,
          zipCode: zipCode,
        });

        console.log(groupName + "\n" +
          res.user.uid + "\n" +
          organization + "\n" +
          groupWebsite + "\n" +
          street + "\n" +
          state + "\n" +
          zipCode)

        toast({
          title: "Account and group created successfully",
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      } catch (error) {
        toast({
          title: "Account and group sign up failed",
          description: error.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      } finally {
        setLoading(false);
      }
    }
  

  return { register, isLoading };
}

export default function App() {
  const { register: signup, isLoading } = useRegister();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [COLOR, setColor] = useState("yellow");

  async function handleRegister(data) {
    signup({
      name: data.name,
      phone: data.phone,
      username: data.username,
      email: data.email,
      password: data.password,
      gender: data.gender,

      organization: data.organization,
      groupName: data.groupName,
      groupWebsite: data.groupWebsite,
      street: data.street,
      state: data.state,
      zipCode: data.zipCode
    });
    reset();
  }

  return (
    <ChakraProvider>
    <form onSubmit={handleSubmit(handleRegister)}>
      <Center>
      <Box mx="1" maxW="md" p="9" borderWidth="1px" borderRadius="lg">
        <Heading mb="4" size="lg" textAlign="center">
          Register Organization
        </Heading>

        <FormControl isInvalid={errors.organization} py="2">
            <FormLabel>Group Type</FormLabel>
            <Select placeholder='Select a group type' type="organization" {...register("organization", {required: true})}>
              <option>Mosque</option>
              <option>Business</option>
              <option>Charity</option>
              <option>Organization</option>
            </Select>
            <FormErrorMessage>
              {errors.organization && errors.organization.message}
            </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.groupName} py="2">
            <FormLabel>Name of Group</FormLabel>
            <Input
              placeholder="Group Name"
              {...register("groupName", {required: true})}
            />
            <FormErrorMessage>
              {errors.groupName && errors.groupName.message}
            </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.groupWebsite} py="2">
            <FormLabel>Website of Group (optional)</FormLabel>
            <Input
              placeholder="www.website.com"
              {...register("groupWebsite")}
            />
            <FormErrorMessage>
              {errors.groupWebsite && errors.groupWebsite.message}
            </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.street} py="2">
            <FormLabel>Street Address</FormLabel>
            <Input
              placeholder="Street Address"
              {...register("street", {required: true})}
            />
            <FormErrorMessage>
              {errors.street && errors.street.message}
            </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.state} py="2">
            <FormLabel>State</FormLabel>
            <Input
              placeholder="State"
              {...register("state", {required: true})}
            />
            <FormErrorMessage>
              {errors.state && errors.state.message}
            </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.zipCode} py="2">
            <FormLabel>Zip Code</FormLabel>
            <Input
              placeholder="Zip Code"
              {...register("zipCode", {required: true})}
            />
            <FormErrorMessage>
              {errors.zipCode && errors.zipCode.message}
            </FormErrorMessage>
        </FormControl>
      </Box>

      <Box mx="1" maxW="md" p="9" borderWidth="1px" borderRadius="lg">
        <Heading mb="4" size="lg" textAlign="center">
          Register
        </Heading>

          <FormControl isInvalid={errors.name} py="2">
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="name"
              {...register("name", nameValidate)}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.username} py="2">
            <FormLabel>Username</FormLabel>
            <Input
              placeholder="username"
              {...register("username", usernameValidate)}
            />
            <FormErrorMessage>
              {errors.username && errors.username.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.phone} py="2">
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="phone"
              placeholder="7775559999"
              {...register("phone", phoneValidate)}
            />
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.email} py="2">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password} py="2">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="password123"
              {...register("password", passwordValidate)}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.gender} py="2">
              <FormLabel>Gender</FormLabel>
              <RadioGroup>
                <Stack spacing={5} direction='row'>
                  <Radio {...register("gender", genderValidate)} colorScheme='blue' value='1'>Male</Radio>
                  <Radio {...register("gender", genderValidate)} colorScheme='pink' value='2'>Female</Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>
                {errors.gender && errors.gender.message}
              </FormErrorMessage>
            </FormControl>

          <Button
            mt="4"
            type="submit"
            colorScheme={COLOR}
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Signing Up"
          >
            Register
          </Button>
      </Box>
      </Center>
      </form>
    </ChakraProvider>
  );
}